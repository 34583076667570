import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import BlogPostCard from "../../pages/blog/BlogPostCard"
import { sortByDate } from "../../../utils/dateUtil"
import { mapPost } from "../../../gatsby/transformers/blogTransformer"
import { borderGrey } from "../../constants/colors"
import SecondaryHeader, { StyledHeader } from "../title/SecondaryHeader"
import BlogFeed from "./BlogFeed"

const Container = styled.div<{ className: string }>``

export const BlogFeedContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  border-top: 1px solid ${borderGrey};
  border-bottom: 1px solid ${borderGrey};
  padding-top: 50px;

  ${StyledHeader} {
    margin-bottom: 40px;
  }
`

const Posts = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

const defaultHeader = <SecondaryHeader>Blog</SecondaryHeader>

interface BlogFeedProps {
  header?: ReactNode
  titleAlign?: string | undefined
  isDateVisible?: boolean
  className?: string
}

const HomeBlogFeed: FC<BlogFeedProps> = ({
  titleAlign = "center",
  header = defaultHeader,
  isDateVisible = false,
  className,
}): JSX.Element => {
  const allPosts = useStaticQuery(queryString).allPost.nodes
  const posts = sortByDate(allPosts)
    .filter(post => !post.categories.some(category => category.slug === "press-releases"))
    .slice(0, 4)

  return (
    <BlogFeed
      posts={posts}
      className={className}
      header={header}
      isDateVisible={isDateVisible}
      titleAlign={titleAlign}
    />
  )
}

const queryString = graphql`
  {
    allPost(limit: 10) {
      nodes {
        uri
        id
        title
        date
        content
        slug

        bannerImage {
          srcSet
          src
          sizes
          id
          alt
        }

        featuredImage {
          srcSet
          src
          sizes
          id
          alt
        }
        categories {
          slug
        }
      }
    }
  }
`

export default HomeBlogFeed
