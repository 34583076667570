import * as React from "react"
import { useState } from "react"
import styled from "styled-components"
import { PrimaryButton } from "@social-supermarket/social-supermarket-components"
import { minionFont } from "../../../../../constants/fonts"
import { desktop, tablet } from "../../../../../constants/screenSizes"
import LPFeatureTicks from "../selling-points/LPFeatureTicks"
import RipplePrimary from "../../../../../../svg/tss-ripple-primary.svg"
import RippleDark from "../../../../../../svg/tss-ripple-primary-dark.svg"
import RippleRouge from "../../../../../../svg/tss-ripple-rouge.svg"
import RippleMustard from "../../../../../../svg/tss-ripple-mustard.svg"
import { navigate } from "gatsby"
import { colors, screenSizes } from "@social-supermarket/social-supermarket-model"

const ripple = [RipplePrimary, RippleRouge, RippleMustard]

const Container = styled.div`
  width: 100%;
  background-color: ${colors.grey};
`

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1500px;

  margin: auto;

  @media (max-width: ${screenSizes.desktop}px) {
    flex-direction: column;
  }
`

const Body = styled.div<{ tab: TabType }>`
  flex: 3;
  background-color: ${({ tab }) => tab.bodyBackground};
  padding: 40px;
  padding-left: 50px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  order: 1;

  @media (max-width: ${screenSizes.desktop}px) {
    padding: 40px 20px;
  }
`
const Tabs = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${screenSizes.desktop}px) {
    flex-direction: row;
  }
`
const Tab = styled.div<{ tab: TabType; selected: boolean }>`
  flex: 1;
  background-color: ${({ tab, selected }) => (selected ? tab.tabBackground : tab.tabBackground)};
  padding: 20px;
  cursor: pointer;
  position: relative;
  color: ${({ tab }) => tab.textColor};

  &:hover {
    background-color: ${({ tab, selected }) =>
      selected ? tab.tabBackground : tab.tabHoverBackground};
  }

  @media (max-width: ${screenSizes.desktop}px) {
    font-size: 0.9em;
  }
`
const TabTitle = styled.div<{ tab: TabType }>`
  font-family: ${minionFont};
  font-size: 30px;
  margin-bottom: 10px;
  color: ${({ tab }) => tab.textColor};

  @media (max-width: ${screenSizes.desktop}px) {
    font-size: 23px;
  }
`
const TabBody = styled.div`
  font-size: 0.9em;
`
const Triangle = styled.div<{ tab: TabType }>`
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: none;
  border-left: 20px solid ${({ tab }) => tab.tabBackground};
  position: absolute;
  top: calc(50% - 20px);
  right: -19px;

  @media (max-width: ${screenSizes.desktop}px) {
    border-top: 20px solid ${({ tab }) => tab.tabBackground};
    border-bottom: none;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    right: calc(50% - 19px);
    bottom: -20px;
    top: unset;
  }
`
const MainTitle = styled.h1`
  margin: 0;
  margin-bottom: 35px;
  font-family: ${minionFont};
  font-size: 35px;
  color: ${({ textColor }) => (textColor ? textColor : "rgb(50,50,50)")};
  text-shadow: 0 0 0px #000000;

  @media (max-width: ${tablet}px) {
    font-size: 28px;
  }
`

const SellingPoints = styled(LPFeatureTicks)`
  margin-left: 40px;
  margin-bottom: 45px;
`

const RippleContainer = styled.div<{ ripple: any }>`
  background: url(${({ ripple }) => ripple});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  width: 800px;
  height: 800px;
  bottom: -700px;
  left: 350px;
  transform: rotate(30deg);

  opacity: 0.35;

  @media (max-width: ${desktop}px) {
    width: 600px;
    height: 600px;
    bottom: ${({ invert }) => (invert ? "-440px" : "-300px")};
    right: ${({ invert }) => (invert ? "unset" : "-490px")};
    left: ${({ invert }) => (invert ? "-400px" : "unset")};
  }
`
const PrimaryActionButton = styled(PrimaryButton)<{ tab: TabType }>`
  background-color: ${({ tab }) => tab.controlsColor} !important;
`

interface TabType {
  tabTitle: string
  tabText: string
  body: React.ReactNode | string
  bodyBackground: string
  tabBackground: string
  tabHoverBackground: string
  bodyTitle: string
  sellingPoints: string[]
  ripple: any
  controlsColor: string
  textColor: string
  to: string
}

const TABS: TabType[] = [
  {
    tabTitle: "For business",
    tabText: "Find out how we can help your business.",
    body: <div>business body</div>,
    bodyBackground: "#fcf7eb",
    tabBackground: "#e5ba4b",
    tabHoverBackground: "#ebc872",
    controlsColor: colors.primaryDark,
    textColor: "black",
    bodyTitle:
      "Boost your ESG initiatives with instant access to over 2000 Social Enterprise products",
    sellingPoints: [
      "UK's largest social enterprise marketplace",
      "Automated impact tracking",
      "Integrate directly with your procurement system",
    ],
    ripple: RippleDark,
    to: "/corporate",
  },
  {
    tabTitle: "For individuals",
    tabText: "Shopping for yourself? Find out more here.",
    body: <div>individual body</div>,
    bodyBackground: "#fcf7eb",
    tabBackground: "#005e71",
    tabHoverBackground: "#176e80",
    controlsColor: colors.primaryDark,
    textColor: "white",
    bodyTitle: "Easily find and buy from inspiring, impactful social enterprise brands",
    sellingPoints: [
      "UK's largest social enterprise marketplace",
      "Browse by impact",
      "Send gifts that do good",
    ],
    ripple: RippleDark,
    to: "/about",
  },
]

const LPBannerTabsBlock = () => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const handleClick = (index: number) => {
    setTabIndex(index)
  }

  const currentTab = TABS[tabIndex]

  return (
    <Container tab={currentTab}>
      <InnerContainer>
        <Body tab={currentTab}>
          <MainTitle>{currentTab.bodyTitle}</MainTitle>
          <SellingPoints
            listItems={currentTab.sellingPoints}
            iconColor={currentTab.controlsColor}
          />
          <PrimaryActionButton slim tab={currentTab} onClick={() => navigate(currentTab.to)}>
            Find out more
          </PrimaryActionButton>
          <RippleContainer ripple={currentTab.ripple} />
        </Body>
        <Tabs>
          {TABS.map((tab, i) => (
            <Tab tab={tab} onClick={() => handleClick(i)} selected={tabIndex === i}>
              {tabIndex === i && <Triangle tab={tab} />}
              <TabTitle tab={tab}>{tab.tabTitle}</TabTitle>
              <TabBody>{tab.tabText}</TabBody>
            </Tab>
          ))}
        </Tabs>
      </InnerContainer>
    </Container>
  )
}

export default LPBannerTabsBlock
