import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import BlogPostCard from "../../pages/blog/BlogPostCard"
import { sortByDate } from "../../../utils/dateUtil"
import { mapPost } from "../../../gatsby/transformers/blogTransformer"
import { borderGrey } from "../../constants/colors"
import SecondaryHeader, { StyledHeader } from "../title/SecondaryHeader"
import PostType from "../../model/BlogType"

const Container = styled.div``

export const BlogFeedContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  border-top: 1px solid ${borderGrey};
  border-bottom: 1px solid ${borderGrey};
  padding-top: 50px;

  ${StyledHeader} {
    margin-bottom: 40px;
  }
`

const Posts = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

const defaultHeader = <SecondaryHeader>Blog</SecondaryHeader>

interface BlogFeedProps {
  header?: ReactNode
  titleAlign?: string | undefined
  isDateVisible?: boolean
  posts: PostType[]
  className?: string
}

const BlogFeed: FC<BlogFeedProps> = ({
  titleAlign = "center",
  header = defaultHeader,
  isDateVisible = false,
  posts,
  className,
}): JSX.Element => {
  return (
    <Container className={className}>
      <BlogFeedContainer>
        {header}
        <Posts>
          {posts.map(post => (
            <BlogPostCard
              post={post}
              titleAlign={titleAlign}
              key={post.id}
              isDateVisible={isDateVisible}
            />
          ))}
        </Posts>
      </BlogFeedContainer>
    </Container>
  )
}

export default BlogFeed
