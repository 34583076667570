import React from "react"
import * as style from "./Radiation.module.scss"
import { Link } from "gatsby"
import RadiationLines from "../../../svg/radiation.inline.svg"

const Radiation = () => {
  return (
    <div className={style.radiation}>
      <h3>Net Positive Impact</h3>
      <p>
        Instead of offsetting and counteracting, every social enterprise we work with puts more back
        into communities and the planet than it takes to create their products.
      </p>
      <Link to={"about"}>Learn More</Link>

      <div className={style.radiationLines}>
        <RadiationLines />
      </div>
    </div>
  )
}

export default Radiation
