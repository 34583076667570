import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SellingPoints from "../../selling-points/SellingPoints"

const HomeSellingPoints = () => {
  const sellingPoints = useStaticQuery(queryString).allWp.nodes[0].options.custom.sellingPoints
  return <SellingPoints sellingPoints={sellingPoints} />
}

const queryString = graphql`
  query {
    allWp {
      nodes {
        options {
          custom {
            sellingPoints {
              description
              iconSvg
              title
            }
          }
        }
      }
    }
  }
`

export default HomeSellingPoints
