import React from "react"
import * as style from "./InstaFeed.module.scss"
import Insta from "../../../svg/instagram.inline.svg"
import UnderlineIconLink from "../../../social-supermarket/components/title/UnderlineIconLink"

const InstaFeed = () => {
  return (
    <div className={style.instaFeed}>
      <div>
        <UnderlineIconLink
          url="https://www.instagram.com/thesocialsupermarket"
          external
          label="Follow us @thesocialsupermarket"
          Svg={Insta}
        />
      </div>
      <iframe
        src="https://cdn.lightwidget.com/widgets/a6361ed29d095d709022a0c78910d082.html"
        scrolling="no"
        allowTransparency="true"
        className="lightwidget-widget"
        style={{ width: "100%", border: 0, overflow: "hidden" }}
      />
    </div>
  )
}

export default InstaFeed
