import React from "react"
import { grey } from "../../constant/colors"
import ProductCarousel from "../../social-supermarket/components/carousel/ProductCarousel"
import LandingPageStrip from "../../social-supermarket/components/landing-page/LandingPageStrip"

const HomeProductCarousel = ({ products }) => {
  const brandCount = {}
  const filtered = products.filter(product => {
    if (!product.brand) {
      return false
    }

    if (!brandCount[product.brand.slug]) {
      brandCount[product.brand.slug] = 0
    }

    if (brandCount[product.brand.slug] < 3 && product.visibility?.includes("Marketplace")) {
      brandCount[product.brand.slug] = brandCount[product.brand.slug] + 1
      return true
    }
  })

  return (
    <LandingPageStrip color={grey}>
      <ProductCarousel title="Recently Added" products={filtered} />
    </LandingPageStrip>
  )
}

export default HomeProductCarousel
