import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { colors, SquareImage, UrlImage } from "@social-supermarket/social-supermarket-components"
import { minionFont } from "../../../../../constants/fonts"
import Ripple from "../../../../../../svg/tss-ripple.svg"
import RippleRouge from "../../../../../../svg/tss-ripple-rouge.svg"
import RippleDark from "../../../../../../svg/tss-ripple-primary-dark.svg"
import RippleMustard from "../../../../../../svg/tss-ripple-mustard.svg"
import { desktop, largeDesktop, tablet } from "../../../../../constants/screenSizes"
import { Link, navigate } from "gatsby"
import { primaryDark } from "../../../../../constants/colors"

const backgroundColorMap = ["#f7f9f7", "#f9ecef", "#fcf7eb"]
const ripple = [RippleDark, RippleRouge, RippleMustard]

const Container = styled.div<{ colorWay: number; backgroundColor: string }>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  max-width: 2000px;
  margin: auto;
  display: flex;
  @media (max-width: ${desktop}px) {
    flex-direction: column;
  }
`
const Text = styled.div`
  width: 50%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  order: ${({ invert }) => (invert ? 1 : 2)};
  position: relative;
  overflow: hidden;
  @media (max-width: ${desktop}px) {
    order: 1;
    width: 100%;
    padding: 30px 30px;
  }

  a {
    color: ${primaryDark};
    text-decoration: underline;
  }
`
const RippleContainer = styled.div<{ invert: boolean; colorWay: number }>`
  background: url(${({ colorWay }) => ripple[colorWay]});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  width: 800px;
  height: 800px;
  bottom: ${({ invert }) => (invert ? "-600px" : "-500px")};
  right: ${({ invert }) => (invert ? "unset" : "-600px")};
  left: ${({ invert }) => (invert ? "-500px" : "unset")};

  opacity: 0.35;

  @media (max-width: ${desktop}px) {
    width: 500px;
    height: 500px;
    bottom: ${({ invert }) => (invert ? "-440px" : "-200px")};
    right: ${({ invert }) => (invert ? "unset" : "-480px")};
    left: ${({ invert }) => (invert ? "-300px" : "unset")};
  }
`
const Title = styled.h1`
  font-family: ${minionFont};
  font-size: 2.4em;
  color: rgb(50, 50, 50);
  margin-bottom: 0px;

  @media (max-width: ${largeDesktop}px) {
    font-size: 45px;
  }
`
const Description = styled.div`
  padding: 20px 0;
  line-height: 2em;
  color: rgb(50, 50, 50);

  @media (max-width: ${largeDesktop}px) {
    font-size: 0.9em;
  }

  @media (max-width: ${desktop}px) {
  }

  a {
    text-decoration: underline;
  }
`
const ImageContainer = styled.div`
  order: ${({ invert }) => (invert ? 2 : 1)};
  width: 50%;
  img {
    vertical-align: bottom;
    width: 100%;
    object-fit: cover;
    max-height: 500px;
  }

  @media (max-width: ${desktop}px) {
    width: 100%;
    order: 2;
  }
`

interface Props {
  title: React.ReactNode | string
  imageUrl?: string
  description?: React.ReactNode | string
  descriptionHtml?: string
  invert?: boolean
  colorWay: 0 | 1 | 2
  to?: string
  backgroundColor?: string
  linkLabel?: string
}

const LPImageFeatureBlock: FC<Props> = ({
  imageUrl,
  title,
  description,
  descriptionHtml,
  invert,
  colorWay,
  to,
  backgroundColor = "#f7f9f7",
  linkLabel = "Find Out More",
}) => {
  return (
    <Container colorWay={colorWay} backgroundColor={backgroundColor}>
      <Text invert={invert}>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {descriptionHtml && <Description dangerouslySetInnerHTML={{ __html: descriptionHtml }} />}
        {to && <Link to={to}>{linkLabel}</Link>}
        <RippleContainer invert={invert} colorWay={colorWay} />
      </Text>
      <ImageContainer invert={invert}>
        <UrlImage
          image={{ src: imageUrl, alt: "feature" }}
          onClick={to ? () => navigate(to) : undefined}
        />
      </ImageContainer>
    </Container>
  )
}

export default LPImageFeatureBlock
