import * as React from "react"
import styled from "styled-components"
import { FC } from "react"

const Container = styled.div``

interface Props {
  svg: string
  className?: string
}

const SvgIcon: FC<Props> = ({ svg, className }) => {
  return <div className={className} dangerouslySetInnerHTML={{ __html: svg }} />
}

export default SvgIcon
