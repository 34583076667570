import React, { FC } from "react"
import Arrow from "../../../svg/arrow.inline.svg"
import { Link } from "gatsby"
import { colors } from "@social-supermarket/social-supermarket-model"
import styled from "styled-components"

const Container = styled(Link)`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.8em;

  svg {
    display: inline-block;
    width: 30px !important;
    margin-right: 10px;
    fill: ${colors.primaryLight};
  }
`

interface Props {
  url: string
  label: string
  Svg?: any
  fill: string
  external?: boolean
}
const UnderlineIconLink: FC<Props> = ({ url, label, external, Svg, fill }) => {
  const inline = fill ? { fill } : {}
  return (
    <Container to={url} target={external ? "_blank" : "_self"}>
      {Svg ? <Svg style={inline} /> : <Arrow style={inline} />}
      {label}
    </Container>
  )
}

export default UnderlineIconLink
