import React from "react"
import * as style from "./SellingPoints.module.scss"
import SvgIcon from "../svg/SvgIcon"

const SellingPoints = ({ sellingPoints, title }) => {
  return (
    <div className={style.sellingPoints}>
      {title && <h2>{title}</h2>}
      <div className={style.inner}>
        {sellingPoints.map(sellingPoint => {
          return (
            <div className={style.point}>
              <div className={style.iconz}>
                <SvgIcon svg={sellingPoint.iconSvg} />
              </div>
              <h5>{sellingPoint.title}</h5>
              <p>{sellingPoint.description}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SellingPoints
