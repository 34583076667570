import React, { FC } from "react"
import Underline from "../../../svg/underline.inline.svg"

import styled from "styled-components"

const Container = styled.div`
  display: block;
  line-height: 1.2;

  h3 {
    margin-bottom: -0.4em;
  }
  svg {
    margin-left: -5%;
  }
`

interface Props {
  children: React.ReactNode
  className?: string
}
const UnderlineHeader: FC<Props> = ({ children, className }) => {
  return (
    <Container className={className}>
      <h3>{children}</h3>
      <Underline />
    </Container>
  )
}

export default UnderlineHeader
