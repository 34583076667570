import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import GLink from "../../../gatsby/GLink"
import { primaryDark } from "../../constants/colors"
import { LinkIcon } from "../landing-page/new/components/products/LPProductCarouselFeatureBlock"

const Text = styled.div`
  margin-right: 5px;
`

const Link = styled(GLink)`
  color: ${primaryDark};
  font-weight: bold;
  display: flex;

  &:hover {
    text-decoration: none;
    color: ${primaryDark};
    ${Text} {
      text-decoration: underline;
    }
  }
`

interface Props {
  className?: string
  to: string
  children: React.ReactNode
}

const GoLink: FC<Props> = ({ className, children, to }) => {
  return (
    <Link to={to} className={className}>
      <Text>{children}</Text> <LinkIcon name="arrow right" color={primaryDark} />
    </Link>
  )
}

export default GoLink
