import * as React from "react"
import { FC } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { tablet } from "../../../constant/screenSizes"
import PostType from "../../model/BlogType"
import GImage from "../../../gatsby/GImage"
import { gillFont } from "../../constants/fonts"
import { getMonth } from "../../util/generalUtil"

const Title = styled.h3<{ titleAlign: string }>`
  font-family: Minion serif;
  text-transform: unset;
  font-size: 1.25em;
  text-align: ${({ titleAlign }) => titleAlign};
  margin-top: 30px;
  padding: 0 15px;

  @media (max-width: ${tablet}px) {
    margin-bottom: 40px;
  }
`

const PostCard = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: column;
  ${Title} {
    margin-bottom: 100px;
  }
`

const DateString = styled.p`
  text-align: start;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1.7px;
  padding-top: 5px;
  font-family: ${gillFont};
`

const StyledImg = styled(GImage)`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

interface Props {
  post: PostType
  titleAlign?: string
  isDateVisible?: boolean
}

const BlogPostCard: FC<Props> = ({
  post: { title, featuredImage, slug, date },
  titleAlign = "left",
  isDateVisible = false,
}): JSX.Element => {
  const formattedDate = new Date(date)

  const dateToString = (date = new Date()) => {
    const day = String(date.getDate())
    const month = String(date.getMonth() + 1)
    const year = String(date.getFullYear())

    return `${day}/${month}/${year}`
  }

  return (
    <PostCard>
      <Link to={`/${formattedDate.getFullYear()}/${getMonth(formattedDate)}/${slug}`}>
        {featuredImage && <StyledImg image={featuredImage} />}
        <Title titleAlign={titleAlign}>
          {title}
          {isDateVisible && <DateString>{dateToString(formattedDate)}</DateString>}
        </Title>
      </Link>
    </PostCard>
  )
}

export default BlogPostCard
