import React from "react"
import * as style from "./WhyWeDoThis.module.scss"
import { Link } from "gatsby"
import Arrow from "../../../svg/arrow.inline.svg"

const WhyWeDoThis = () => {
  return (
    <div className={style.whyWeDoThis}>
      <div>
        <h4>Why we do this</h4>
        <p>
          We believe in a world where every business can be a force for good. We curate the best
          products from brands with net-positive impact. That means every purchase you make sends
          out ripples of change. We champion brands that make a real difference in our world.
          There's nothing neutral about us.
        </p>
        <Link to={"/about"}>
          <Arrow />
          Discover More
        </Link>
      </div>
    </div>
  )
}

export default WhyWeDoThis
