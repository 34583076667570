import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { grey } from "../../../../../constants/colors"
import { tablet } from "../../../../../constants/screenSizes"
import Carousel from "../../../../carousel/Carousel"
import { SwiperSlide } from "swiper/react"
import SvgIcon from "../../../../svg/SvgIcon"

const Container = styled.div`
  padding: 70px 0;
  background-color: ${grey};

  @media (max-width: ${tablet}px) {
    padding: 60px 10px;
  }
`
const Slide = styled.div`
  @media (max-width: ${tablet}px) {
    padding-bottom: 40px;
  }

  img {
    margin: 0;
  }

  div {
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 0.8em;

    @media (max-width: ${tablet}px) {
      padding-left: 10px;
      text-align: center;
    }
  }
`

const Svg = styled(SvgIcon)`
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;

  @media (min-width: ${tablet}px) {
    &:hover {
      width: 110%;
      height: 110%;
      margin-left: -5%;
      margin-top: -5%;
      margin-bottom: -5%;
    }
  }
`

interface Props {}

const LPShopByImpactCarouselBlock: FC<Props> = () => {
  const impact = useStaticQuery(queryString).allWpImpact.nodes
  return (
    <Container>
      <Carousel
        title="Shop By Values"
        showArrows
        loop
        swiperProps={{
          slidesPerView: 3,
          slidesPerGroup: 3,
          breakpoints: {
            500: {
              slidesPerView: 4,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 4,
              slidesPerGroup: 1,
              pagination: false,
              allowTouchMove: false,
            },
            956: {
              slidesPerView: 7,
              slidesPerGroup: 1,
              pagination: false,
              allowTouchMove: false,
            },
          },
        }}
      >
        {impact.map(impact => (
          <SwiperSlide>
            <Link to={`/impact/${impact.slug}`}>
              <Slide>
                <Svg svg={impact.icon.iconSvg} />
              </Slide>
            </Link>
          </SwiperSlide>
        ))}
      </Carousel>
    </Container>
  )
}

const queryString = graphql`
  query {
    allWpImpact {
      nodes {
        name
        slug
        icon {
          iconSvg
        }
      }
    }
  }
`

export default LPShopByImpactCarouselBlock
